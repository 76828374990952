<template>
  <div class="top flex flex-centers flex-space">
    <div class="flex flex-centers coh1-1">
      <img src="../assets/logo.png" class="logoimg" />
    </div>
    <div class="flex flex-centers coh1-1">
      <div
        @click="bindtap(1)"
        class="title flex flex-center"
        :class="active == 1 ? 'select' : ''"
      >
        <div>首页</div>
        <div class="selectline"></div>
      </div>
      <div
        @click="bindtap(2)"
        class="title flex flex-center"
        :class="active == 2 ? 'select' : ''"
      >
        <div>生态服务能力</div>
        <div class="selectline"></div>
      </div>
      <div
        @click="bindtap(3)"
        class="title flex flex-center"
        :class="active == 3 ? 'select' : ''"
      >
        <div>应用创新</div>
        <div class="selectline"></div>
      </div>
      <div
        @click="bindtap(4)"
        class="title flex flex-center"
        :class="active == 4 ? 'select' : ''"
      >
        <div>中心动态</div>
        <div class="selectline"></div>
      </div>
      <div
        @click="bindtap(5)"
        class="title flex flex-center"
        :class="active == 5 ? 'select' : ''"
      >
        <div>关于我们</div>
        <div class="selectline"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState,mapActions} from "vuex";
export default {
  name: "Home",
  components: {},
  props: ["active"],
  mounted() {
    if(!this.topMenus.complete){
      // 初始化顶部导航
      this.initTopMenus(this.$http);
    }
  },
  computed:{
    ...mapState(["topMenus"])
  },
  methods: {
    ...mapActions(["initTopMenus"]),
    //   页面跳转
    bindtap(val) {
      let that = this;
      let active = that.active;
      if (active != val) {
        if (val == 1) {
          that.$router.push({
            path: "/",
          });
        } else if (val == 2) {
          that.$router.push({
            path: "/services",
          });
        } else if (val == 3) {
          that.$router.push({
            path: "/innovation",
          });
        } else if (val == 4) {
          that.$router.push({
            path: "/centralnews",
          });
        } else if (val == 5) {
          that.$router.push({
            path: "/aboutus",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.top {
  width: 100%;
  background: #1a5df1;
  height: 89px;
  box-shadow: 0px -1Px 0px 0px rgba(255, 255, 255, 0.2) inset;
  padding: 0 360px;
}
.logotitle {
  font-size: 32px;
  color: #fff;
}
.title {
  height: 100%;
  position: relative;
  margin: 0 35px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}
.select .selectline {
  position: absolute;
  bottom: 0;
  width: 77px;
  height: 3px;
  background: #fff;
}
.coh1-1 {
  height: 100%;
}
.logoimg {
  width: 234px;
  height: 86px;
}
</style>