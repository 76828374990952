<template>
  <div>
    <defaulttop active="5"></defaulttop>
    <div class="swipertop flex flex-space">
      <div class="coh1-1 flex flex-centers">
        <img src="../assets/aboutbg1.png" class="aboutbg1img" />
      </div>
      <div class="coh1-1 flex flex-bottom">
        <img src="../assets/aboutbg2.png" class="aboutbg2img" />
      </div>
    </div>
    <div class="context flex flex-space">
      <div class="lefttab">
        <div
          @click="bindtab(index)"
          v-for="(item, index) in tablist"
          :key="index"
          :class="selectindex == index ? 'lefttitleselect' : 'lefttitle'"
          class="flex flex-center hovera"
        >
          <span>•</span><span class="ml15">{{ item.label }}</span>
        </div>
      </div>
      <div class="rightcontext" v-if="selectindex == 0">
        <img src="../assets/aboutbg3.png" class="aboutbg3img" />
        <div class="prititle">重庆大数据人工智能创新中心有限公司</div>
        <div class="sectitle mt36">企业简介</div>
        <div class="contexttitle">
          重庆大数据人工智能创新中心由成都大数据产业技术研究院与重庆渝隆集团，成渝两地国资联合创建。中心旨在打造成渝双城地区经济圈大数据人工智能创新生态，推动大数据智能化产业落地与应用实践。
        </div>
        <div class="contexttitle">
          中心定位于聚焦大数据智能化领域的新型孵化平台，以数据治理为内核，以智慧城市为外延，以投资孵化为纽带，以市场开拓为导向，着力建设“一中心、一基金、一管道、一集群”，形成大数据智能化应用创新企业集群与产业生态。
        </div>
        <div class="contexttitle">
          旗下聚集：从事大数据智能化前沿研究的信风智库、从事大数据技术开发的玖数科技、从事数字孪生应用的第五维科技等优质创新企业。
        </div>
        <div class="contexttitle">
          中心构建了政务与公共数据治理、智慧城市多场景应用以及三农行业信息化建设等多维度解决方案体系，联合多个地方政府与企事业单位，实施了智慧政府管理平台、智慧城市运营平台、政企精准匹配平台、普惠金融服务平台、投融资项目管理平台以及大数据精准招商平台等一系列大数据智能化应用项目，为地方产业发展、企业创新、民生保障以及政务管理进行深度赋能。
        </div>
      </div>
      <div class="rightcontext" v-if="selectindex == 1">
        <img src="../assets/aboutbg3.png" class="aboutbg3img" />
        <div class="prititle">重庆大数据人工智能创新中心有限公司</div>
        <div class="sectitle mt36">专家团队</div>
        <div class="flex mt40">
          <img src="../assets/about1.png" class="peopleimg1s" />
          <div class="ml58">
            <div class="mt10 txt1">ZHOUTAO</div>
            <div class="mt10 txt2">周涛</div>
            <div class="mt10 txt3">
              <div>电子科技大学教授</div>
              <div>互联网科学中心主任、博士生导师</div>
              <div>入选国家“万人计划”青年人才支持计划</div>
              <div>荣获共青团中央2015年度中国十大科技创新人物奖</div>
              <div>重庆大数据人工智能创新中心首席科学家</div>
            </div>
          </div>
        </div>
        <div class="flex mt40">
          <div class="w330">
            <img src="../assets/about2.png" class="peopleimg1" />
            <div class="mt36">
              <div class="txt1">ZHANGXIAOSONG</div>
              <div class="mt10 txt2">张小松</div>
              <div class="mt10 txt3">
                <div>电子科技大学教授</div>
                <div>大数据研究院院长</div>
                <div>网络安全实验室主任</div>
                <div>博士生导师</div>
                <div>重庆大数据人工智能创新中心科学家</div>
              </div>
            </div>
          </div>
          <div class="ml58 w330">
            <img src="../assets/about3.png" class="peopleimg1" />
            <div class="mt36">
              <div class="txt1">XUZENGLIN</div>
              <div class="mt10 txt2">徐增林</div>
              <div class="mt10 txt3">
                <div>电子科技大学教授</div>
                <div>大数据研究中心数据挖掘与推理研究所所长</div>
                <div>博士生导师</div>
                <div>人工智能领域主要国际会议AAAI/IJCAI程序委员会成员</div>
                <div>重庆大数据人工智能创新中心科学家</div>
              </div>
            </div>
          </div>
        </div>
        <div class="sectitle mt36">顾问团队</div>
        <div class="flex flex-wrap">
          <div class="col3-1 adviser flex flex-centers mt30">
            <img src="../assets/about4.png" class="adviserimg" />
            <div class="">
              <div class="txt2">王勇</div>
              <div class="txt4 mt5">特聘专家</div>
            </div>
          </div>
          <div class="col3-1 adviser flex flex-centers mt30">
            <img src="../assets/about5.png" class="adviserimg" />
            <div class="">
              <div class="txt2">傅彦</div>
              <div class="txt4 mt5">教授，博导</div>
            </div>
          </div>

          <div class="col3-1 adviser flex flex-centers mt30">
            <img src="../assets/about6.png" class="adviserimg" />
            <div class="">
              <div class="txt2">刘明</div>
              <div class="txt4 mt5">教授，博导</div>
            </div>
          </div>
          <div class="col3-1 adviser flex flex-centers mt30">
            <img src="../assets/about7.png" class="adviserimg" />
            <div class="">
              <div class="txt2">高辉</div>
              <div class="txt4 mt5">教授，博导</div>
            </div>
          </div>
          <div class="col3-1 adviser flex flex-centers mt30">
            <img src="../assets/about8.png" class="adviserimg" />
            <div class="">
              <div class="txt2">邵俊明</div>
              <div class="txt4 mt5">教授，博导</div>
            </div>
          </div>
          <div class="col3-1 adviser flex flex-centers mt30">
            <img src="../assets/about9.png" class="adviserimg" />
            <div class="">
              <div class="txt2">杨国武</div>
              <div class="txt4 mt5">教授，博导</div>
            </div>
          </div>
          <div class="col3-1 adviser flex flex-centers mt30">
            <img src="../assets/about10.png" class="adviserimg" />
            <div class="">
              <div class="txt2">陈雷霆</div>
              <div class="txt4 mt5">教授，博导<br/>IET会员</div>
            </div>
          </div>
          <div class="col3-1 adviser flex flex-centers mt30">
            <img src="../assets/about11.png" class="adviserimg" />
            <div class="">
              <div class="txt2">荣志海</div>
              <div class="txt4 mt5">教授，博导</div>
            </div>
          </div>
          <div class="col3-1 adviser flex flex-centers mt30">
            <img src="../assets/about12.png" class="adviserimg" />
            <div class="">
              <div class="txt2">周俊临</div>
              <div class="txt4 mt5">副博士，教授<br/>数联寻英创始人，CEO</div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightcontext" v-if="selectindex == 2">
        <img src="../assets/aboutbg3.png" class="aboutbg3img" />
        <div class="prititle">重庆大数据人工智能创新中心有限公司</div>
        <div class="sectitle mt36">加入我们</div>
        <div class="gangwei">{{`- 在招岗位（ 简历投递：${$web.email_resume_delivery} ）`}}</div>
        <div class="fourtitle flex flex-centers hovera"  @click="btnjoin(1)">
          <div>产品经理</div>
          <img src="../assets/arrow.png" :class="jointype==1?'':'arrowbottom'" class="arrowtop ml10" />
        </div>
        <div class="fourcontext" v-if="jointype==1">
          <div>工作职责：</div>
          <div>1.负责与客户进行需求沟通，完成需求调研，评估，分析工作</div>
          <div>
            2.梳理业务逻辑，完成产品的功能、界面、交互设计，产出原型及PRD
          </div>
          <div>3.与研发、测试、UI/UED人员协调沟通，推动产品的落地</div>
          <div>
            4.进行业务建模、原型设计，撰写各类产品说明文档，包含PRD、产品架构图、需求列表等
          </div>
          <div>5.持续跟进产品研发、测试工作，确保开发成果的准确性和及时性</div>
          <div>6.配合产品验收交付过程中所需的资料，开展客户的使用培训</div>
          <div>任职要求：</div>
          <div>1.本科及以上，计算机或者统计学相关专业</div>
          <div>2.至少熟练使用一种产品原型设计工具</div>
          <div>3.至少经历1个产品完整生命周期</div>
          <div>4.了解大数据平台产品开发的基本概念及流程</div>
          <div>5.有产品迭代经验，善于运用自身的技术能力进行基本的产品可研</div>
          <div>6.有优秀的审美能力和逻辑思维、口头沟通能力</div>
          <div>7.面对突发情况快速响应，并给出解决方案</div>
          <div>
            8.有如下工作经历优先考虑：政府客户对接经验、数据建模、B端软件研发经验
          </div>
        </div>
        <div class="fourtitle flex flex-centers hovera" @click="btnjoin(2)">
          <div>后端工程开发师</div>
          <img src="../assets/arrow.png" :class="jointype==2?'':'arrowbottom'" class="arrowtop ml10" />
        </div>
        <div class="fourcontext" v-if="jointype==2">
          <div>工作职责：</div>
          <div>1.负责与客户进行需求沟通，完成需求调研，评估，分析工作</div>
          <div>
            2.梳理业务逻辑，完成产品的功能、界面、交互设计，产出原型及PRD
          </div>
          <div>3.与研发、测试、UI/UED人员协调沟通，推动产品的落地</div>
          <div>
            4.进行业务建模、原型设计，撰写各类产品说明文档，包含PRD、产品架构图、需求列表等
          </div>
          <div>5.持续跟进产品研发、测试工作，确保开发成果的准确性和及时性</div>
          <div>6.配合产品验收交付过程中所需的资料，开展客户的使用培训</div>
          <div>任职要求：</div>
          <div>1.本科及以上，计算机或者统计学相关专业</div>
          <div>2.至少熟练使用一种产品原型设计工具</div>
          <div>3.至少经历1个产品完整生命周期</div>
          <div>4.了解大数据平台产品开发的基本概念及流程</div>
          <div>5.有产品迭代经验，善于运用自身的技术能力进行基本的产品可研</div>
          <div>6.有优秀的审美能力和逻辑思维、口头沟通能力</div>
          <div>7.面对突发情况快速响应，并给出解决方案</div>
          <div>
            8.有如下工作经历优先考虑：政府客户对接经验、数据建模、B端软件研发经验
          </div>
        </div>
        <div class="fourtitle flex flex-centers hovera"  @click="btnjoin(3)">
          <div>前端工程开发师</div>
          <img src="../assets/arrow.png" :class="jointype==3?'':'arrowbottom'" class="arrowtop ml10" />
        </div>
        <div class="fourcontext" v-if="jointype==3">
          <div>工作职责：</div>
          <div>1.负责与客户进行需求沟通，完成需求调研，评估，分析工作</div>
          <div>
            2.梳理业务逻辑，完成产品的功能、界面、交互设计，产出原型及PRD
          </div>
          <div>3.与研发、测试、UI/UED人员协调沟通，推动产品的落地</div>
          <div>
            4.进行业务建模、原型设计，撰写各类产品说明文档，包含PRD、产品架构图、需求列表等
          </div>
          <div>5.持续跟进产品研发、测试工作，确保开发成果的准确性和及时性</div>
          <div>6.配合产品验收交付过程中所需的资料，开展客户的使用培训</div>
          <div>任职要求：</div>
          <div>1.本科及以上，计算机或者统计学相关专业</div>
          <div>2.至少熟练使用一种产品原型设计工具</div>
          <div>3.至少经历1个产品完整生命周期</div>
          <div>4.了解大数据平台产品开发的基本概念及流程</div>
          <div>5.有产品迭代经验，善于运用自身的技术能力进行基本的产品可研</div>
          <div>6.有优秀的审美能力和逻辑思维、口头沟通能力</div>
          <div>7.面对突发情况快速响应，并给出解决方案</div>
          <div>
            8.有如下工作经历优先考虑：政府客户对接经验、数据建模、B端软件研发经验
          </div>
        </div>
      </div>
      <div class="rightcontext" v-if="selectindex == 3">
        <img src="../assets/aboutbg3.png" class="aboutbg3img" />
        <div class="prititle">{{$web.companyName}}</div>
        <div class="sectitle mt36">联系我们</div>
        <div class="mt20 tretitle">电话：{{$web.phone}}</div>
        <div class="flex tretitle">
          <div class="">邮件：</div>
          <div>
            <div>{{$web.email_business}}</div>
            <div>{{$web.email_hr}}</div>
          </div>
        </div>
        <div class="tretitle">
          地址：{{$web.address}}
        </div>
        <img class="qrcodeimg" src="../assets/qrcode.png" />
        <baidu-map :center="center" :zoom="zoom" @ready="handler" class="map">
          <bm-geolocation
            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
            :showAddressBar="mapshow"
            :autoLocation="mapshow"
          ></bm-geolocation>
          <!--            标记-->
          <bm-marker
            :position="{ lng: center.lng, lat: center.lat }"
            @click="infoWindowOpen"
          >
            <bm-info-window
              :show="mapshow"
              @close="infoWindowClose"
              :width="0"
              :height="0"
              v-html="$web.mapAddress"
            >
            </bm-info-window>
          </bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import defaulttop from "../components/defaulttop";
export default {
  name: "AboutUs",
  data() {
    return {
      selectindex: 0,
      tablist: [
        {
          label:"企业介绍",
          value: 1
        },
        {
          label:"专家团队",
          value: 2
        },
        {
          label:"加入我们",
          value: 3
        },
        {
          label:"联系我们",
          value: 4
        }],
      center: {
        lng: 106.519732,
        lat: 29.515614,
      },
      zoom: 17,
      mapshow: true,
      jointype:1,//1.产品经理 2.后端 3.前端
    };
  },
  components: {
    defaulttop,
  },
  computed:{
    ...mapState(["nowChoicePage"])
  },
  mounted() {
    if(this.$route.query && this.$route.query.classid){
      const idx = this.tablist.findIndex(item=>{
        return item.value == this.$route.query.classid;
      });
      if(idx!=-1) this.selectindex = idx;
    }
  },
  methods: {
    // 是否展开加入我们
    btnjoin(e){
      this.jointype = e;
    },
    handler({ BMap, map }) {
      map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      // 中心点
      this.center.lng = this.center.lng;
      this.center.lat = this.center.lat;
      this.zoom = this.zoom;
    },
    // 关闭窗体
    infoWindowClose() {
      this.mapshow = false;
    },
    // 打开窗体
    infoWindowOpen() {
      this.mapshow = true;
    },
    // 切换左边tab
    bindtab(index) {
      this.selectindex = index;
    },
  },
  watch:{
    nowChoicePage:{
      handler(newValue, oldValue){
        const idx = this.tablist.findIndex(item=>{
          return item.value == newValue.classid;
        });
        if(idx!=-1) this.selectindex = idx;
      },
      deep:true
    }
  }
};
</script>
<style scoped>
.context {
  margin: 38px 0 8px 0;
  padding: 0 360px;
}
.lefttab {
  width: 10vw;
}
.lefttitle {
  height: 50px;
  color: #999;
  font-size: 16px;
  border-right: 1px solid #e5e4e8;
}
.lefttitleselect {
  height: 50px;
  color: #1a5df1;
  font-size: 16px;
  border-right: 2px solid #205cf4;
  background: linear-gradient(
    90deg,
    #eff3f9 0%,
    rgba(255, 255, 255, 0.98) 100%
  );
}

.rightcontext {
  width:calc(100vw - 37.5vw - 12.1875vw);
  min-height: 1087px;
  background: #ffffff;
  padding: 47px 64px;
  position: relative;
}
.prititle {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}
.sectitle {
  font-size: 18px;
  font-weight: bold;
  color: #1a5df1;
}

.contexttitle {
  font-size: 16px;
  font-weight: 300;
  color: #9da3ab;
  line-height: 34px;
  text-indent: 2em;
  margin-top: 30px;
}
.map {
  height: 500px;
}
.aboutbg3img {
  position: absolute;
  width: 231px;
  height: 507px;
  z-index: 0;
  right: 0;
  top: 0;
}
.swipertop {
  width: 100%;
  height: 215px;
  background: #1a5df1;
  padding: 0 360px;
}
.aboutbg1img {
  width: 371px;
  height: 126px;
}
.coh1-1 {
  height: 100%;
}
.aboutbg2img {
  width: 436px;
  height: 190.2px;
}
.tretitle {
  font-size: 16px;
  color: #9da3ab;
  line-height: 34px;
}
.qrcodeimg {
  width: 108px;
  height: 108px;
  margin: 20px 0 30px 0;
}
.gangwei {
  font-size: 18px;
  color: #333333;
  margin-top: 20px;
}
.fourtitle {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin-top: 30px;
}
.arrowtop {
  width: 20px;
  height: 20px;
}

.arrowbottom {
  transform: rotate(180deg);
}
.fourcontext {
  padding: 25px 30px;
  background: rgba(245, 246, 251, 0.3);
  border: 1px solid #6b96f6;
  margin-top: 30px;
  font-size: 16px;
  color: #9da3ab;
  line-height: 34px;
}
.peopleimg1 {
  width: 330px;
  height: 186px;
}
.peopleimg1s {
  width: 330px;
  height: 216px;
}
.w330 {
  width: 330px;
}

.ml58 {
  margin-left: 58px;
}
.txt1 {
  font-size: 20px;
  color: #333333;
}
.txt2 {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}
.txt3 {
  font-family: SourceHanSansCN;
  font-size: 14px;
  color: #555555;
  line-height: 23px;
}
.txt4 {
  font-family: Source Han Sans;
  font-size: 14px;
  color: #555555;
  line-height: 30px;
}
.col3-1 {
  width: 33.33%;
}

.adviser:nth-child(3n + 1) {
  padding-right: 20px;
}
.adviser:nth-child(3n + 2) {
  padding-right: 10px;
  padding-left: 10px;
}
.adviser:nth-child(3n) {
  padding-left: 20px;
}
.adviserimg {
  width: 137px;
  height: 137px;
  margin-right: 16px;
}
</style>
